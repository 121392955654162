<template>
  <div>
    <ViewLoadingMessage :loading="firstLoading" />

    <CRow v-if="!firstLoading">
      <CCol lg="12">
        <CCard class="no-borders">
          <CCardHeader>
            <div class="d-flex flex-row align-items-center">
              <h1 class="main-header mr-4" v-translate>Events Data Entry</h1>
            </div>
          </CCardHeader>

          <CCardBody>
            <CDataTable
              class="custom-table table-sm"
              :fields="fields"
              :items="filteredTableData()"
              :hover="false"
              :striped="true"
              :bordered="true"
              :small="true"
              :fixed="false"
              :no-items-view="{
                noResults: this.$gettext('no filtering results available'),
                noItems: this.$gettext('No events found'),
              }"
              :column-filter="true"
              :sorter="true"
              pagination
              cleaner
            >
              <template #no-items-view>
                <div class="text-center">
                  <h5 v-translate>No events found</h5>
                </div>
              </template>
              <template #sorting-icon="{ state, classes }">
                <div :class="classes">
                  <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                  <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                </div>
              </template>


              <template #start_at-filter>
                <input
                  class="form-control form-control-sm"
                  type="date"
                  v-model="startDateFilter"
                />
                <input
                  class="form-control form-control-sm"
                  type="date"
                  v-model="endDateFilter"
                  v-if="startDateFilter"
                />
              </template>

              <template #start_at="{ item }">
                <td>{{ item.start_at | formatDate }}</td>
              </template>

              <template #is_promo-filter>
                <CSelect
                  size="sm"
                  class="mb-"
                  :options="freePaidOptions"
                  v-model="freePaidFilter"
                />
              </template>

              <template #category_industry-filter>
                <CSelect
                  size="sm"
                  class="mb-"
                  :options="categoryIndustryOptions"
                  v-model="categoryIndustryFilter"
                />
              </template>

              <template #is_promo="{ item }">
                <td class="text-center">
                  <CIcon
                    :name="item.is_promo ? 'cipDotGreen' : 'cipDotGray'"
                    size="custom-size"
                    :height="10"
                  />
                </td>
              </template>

              <template #turnover="{ item }">
                <td class="table-input">
                  <CInput
                    size="sm"
                    v-model="item.turnover"
                    @change="changeTurnover(item, $event)"
                    @input="inputTurnover(item)"
                    :lazy="true"
                    :isValid="item.turnoverIsValid"
                  />
                </td>
              </template>

              <template #presale="{ item }">
                <td class="table-input">
                  <CInput
                    size="sm"
                    v-model="item.presale"
                    @change="changePresale(item, $event)"
                    @input="inputPresale(item)"
                    :lazy="false"
                    :isValid="item.presaleIsValid"
                  />
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import PicaServiceService from "../../services/pica_service.service";
import ViewLoadingMessage from "@/components/ViewLoadingMessage";

export default {
  name: "EventDataEntryList",

  components: {
    ViewLoadingMessage,
  },

  data() {
    return {
      firstLoading: true,
      fields: [
        {
          key: "id",
          label: "ID",
          _style: "width:5%;",
          sorter: true,
        },
        {
          key: "name",
          label: this.$gettext("Name"),
        },
        {
          key: "start_at",
          label: this.$gettext("Start at"),
          filter: true,
        },
        {
          key: "photo_service_name",
          label: this.$gettext("Photo service"),
          sorter: true,
          filter: true,
        },
        {
          key: "is_promo",
          label: this.$gettext("Free"),
          sorter: true,
          filter: true,
        },
        {
          key: "category_industry",
          label: this.$gettext("Industry"),
          sorter: true,
          filter: true,
        },
        {
          key: "turnover",
          label: this.$gettext("Revenues"),
          sorter: false,
          filter: true,
        },
        {
          key: "presale",
          label: this.$gettext("Presale"),
          sorter: false,
          filter: true,
        },
      ],
      tableData: [],
      filteredDataLength: 0,
      startDateFilter: null,
      endDateFilter: null,
      freePaidFilter: "---",
      categoryIndustryFilter: "---",
    };
  },

  mounted() {
    this.fetchAllPicaservicesData();
  },
  computed: {
    freePaidOptions() {
      return [
        {
          value: "---",
          label: this.$gettext("---\u00A0\u00A0\u00A0\u00A0\u00A0"),
        },
        {
          value: "free",
          label: this.$gettext("Free\u00A0\u00A0\u00A0\u00A0\u00A0"),
        },
        {
          value: "paid",
          label: this.$gettext("Paid\u00A0\u00A0\u00A0\u00A0\u00A0"),
        },
      ];
    },
    categoryIndustryOptions() {
      const categorySet = new Set();
      this.tableData.forEach((item) => {
        categorySet.add(item.category_industry);
      });
      return ["---", ...Array.from(categorySet).sort()];
    },
  },
  methods: {
    filteredTableData() {
      let data = this.tableData;
      if (this.startDateFilter) {
        data = data.filter((item) => {
          const date = item.start_at;
          if (this.endDateFilter === null) {
            return date >= this.startDateFilter;
          }
          return this.endDateFilter >= date && date >= this.startDateFilter;
        });
      }
      if (this.freePaidFilter !== "---") {
        data = data.filter((item) => {
          return item.is_promo === (this.freePaidFilter === "free");
        });
      }
      if (this.categoryIndustryFilter !== "---") {
        data = data.filter((item) => {
          return item.category_industry === this.categoryIndustryFilter;
        });
      }
      return data;
    },

    fetchAllPicaservicesData() {
      return PicaServiceService.getPicaServiceForDataEntry().then(
        (response) => {
          this.tableData = response.data.results;
          this.firstLoading = false;
        }
      );
    },

    inputTurnover(item) {
      this.$set(item, "turnoverIsValid", null);
    },

    async changeTurnover(item, value) {
      if (!value) {
        value = 0;
        this.$set(item, "turnover", 0);
      }
      try {
        await PicaServiceService.patchPicaServiceForDataEntry(item.id, {
          turnover: value,
        });
        this.$set(item, "turnoverIsValid", true);
      } catch (error) {
        this.$set(item, "turnoverIsValid", false);
        console.error("error while changing turnover: ", error.message);
      }
    },

    inputPresale(item) {
      this.$set(item, "presaleIsValid", null);
    },

    async changePresale(item, value) {
      if (!value) {
        value = 0;
        this.$set(item, "presale", 0);
      }
      try {
        await PicaServiceService.patchPicaServiceForDataEntry(item.id, {
          presale: value,
        });
        this.$set(item, "presaleIsValid", true);
      } catch (error) {
        this.$set(item, "presaleIsValid", false);
        console.error("error while changing presale: ", error.message);
      }
    },
  },
};
</script>
<style scoped>
.custom-table >>> .pagination {
  padding-left: 20px;
}
</style>
